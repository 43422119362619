/*
* 首页接口
* */
import requests from "@/api/request";

//轮播图
export const reqCarousels = () => requests({ url: `/pc/cjgcnews/cjgcNews/list?sflb=1`, method: 'get' });

//新闻列表
export const reqNewsList = (pageNum=1, pageSize=10) => requests({
    url: `/pc/cjgcnews/cjgcNews/list?sflb=0&pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get',
});

//获取咨询管理的相应类型的列表数据
export const reqTypeList = (params) => requests({
    url: `/pc/cjgcnews/cjgcNews/list`,
    method: 'get',
    params: params
});

//新闻列表
export const reqNewsListByType = (pageNum=1, pageSize=10, category=0, lead=-1) => requests({
    // url: `/pc/cjgcnews/cjgcNews/list?sflb=0&pageNum=${pageNum}&pageSize=${pageSize}&lead=${lead}&newstype=${category}`,
    url: `/pc/cjgcnews/cjgcNews/list?pageNum=${pageNum}&pageSize=${pageSize}&newstype=${category}`,
    method: 'get',
});

//新闻详情
export const reqArticle = (id) => requests({ url: `/pc/cjgcnews/cjgcNews/queryById?id=${id}`, method: 'get' });

//获取信息发布的资讯文章类别
export const reqNewsTypeList = () => requests({ url: `/pc/cjgcnewstype/cjgcNewsType/list`, method: 'get' });

/**
 * 成员单位相关，取牵头单位
 * */
//获取列表
export const reqEnterpriseList = (pageNum=1, pageSize=80) => requests({
    url: `/pc/cjgcmember/cjgcMember/list?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get',
});
/**
 * 取工信中心下面的2个联合体单位
 * */
//获取列表
export const reqYyjglht = (pageNum=1, pageSize=2) => requests({
    url: `/pc/cjgcmember/cjgcMember/list?pageNum=${pageNum}&pageSize=${pageSize}&mtype=2&pid=1760200324850753537`,
    method: 'get',
});

/**
 * 教师专家相关
 * */
//获取列表
export const reqExpertList = (pageNum=1, pageSize=10) => requests({
    url: `/pc/cjgcexpert/mmtExpert/indexlist?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get',
});

/**
 * 产业活动相关
 * */
//获取列表
export const reqActivityList = (params) => requests({
    url: `/pc/cjgcactivity/cjgcActivity/list`,
    method: 'get',
    params: params
});
//获取详情
export const reqActivityDetail = (id) => requests({
    url: `/pc/cjgcactivity/cjgcActivity/queryById?id=${id}`,
    method: 'get',
});

/**
 * 教学活动相关
 * */
//获取列表
export const reqTeacingProgrammeList = (pageNum=1, pageSize=10) => requests({
    url: `/pc/cjgcteachplan/cjgcTeachplan/list?pageNum=${pageNum}&pageSize=${pageSize}&pintro=*课程*`,
    method: 'get',
});


/**
 * 考试认证相关
 * */
//获取列表
export const reqExamList = (pageNum=1, pageSize=10) => requests({
    url: `/pc/cjgcexam/cjgcExam/list?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get',
});

/**
 * 开放课程相关
 * */
//获取列表

export const reqCourseList = (params) => requests({
    url: `/pc/cjgccourse/cjgcCourse/list`,
    method: 'get',
    params: params
});


/**
 * 实训项目相关
 * */
//获取列表
export const reqProjectList = (pageNum=1, pageSize=10) => requests({
    url: `/pc/cjgcproject/cjgcProject/list?pageNum=${pageNum}&pageSize=${pageSize}&img=*http*&isshow=1`,
    method: 'get',
});

/**
 * 实训项目相关，这里应该是取实训项目数量
 * */
//数据看板
export const reqDataBoard = () => requests({
    url: `/pc/cjgcdashboarrd/getindexdata`,
    method: 'get',
});
