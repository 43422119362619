<template>
  <!-- 头部 -->
  <header class="header">
    <div class="top">
      <div class="welcome" v-if="loginFlag">
        <button class="skip" v-if="backgroundManagement" @click="skipBack()">超级工厂后台管理</button>
        <div class="info-text">
          <div class="photo-box">
            <img class="head-photo" :src="`${headPhoto}`"  />
          </div>
          <div id="showDisplay" class="info">
            <div class="arrows"></div>
            <ul class="drop-down">
              <li @click="skipinfo()" >修改信息</li>
              <li @click="myRegistration" >我的报名</li>
              <li @click="clearOut()" class="loginOut"> 退出登录</li>
            </ul>
          </div>
        </div>
        <span class="welcomeToUser"><span class="welcomeToUser" style="font-weight: bold" >{{ name }}</span>,欢迎您回来</span>
      </div>

      <div>
        <img src="@/assets/images/header/slogan.png" alt="" class="slogan"/>
      </div>
      <div class="search-box">
        <img class="icon-search" src="@/assets/images/icon/search.png">
        <input class="search-input" v-model="searchVal" type="text" placeholder="请输入搜索关键字" @keyup.enter="search"/>
        <!--        <router-link to="/searchResult">-->
        <el-button class="btn-search" round type="primary" @click="search">搜索</el-button>
        <!--        </router-link>-->
      </div>
    </div>

    <div class="btns" v-if="!loginFlag">
      <a class="btn" :href="`${registerURL}`">注册</a>
      <span class="divider">/</span>
      <a class="btn" :href="`${loginURL}`">登录</a>
    </div>

    <div class="nav-box">
      <div class="navbar">
        <router-link class="nav-item" :class="{active: navIndex == 0}" to="/index">
          <h1>首页</h1>
          <img src="@/assets/images/icon/active-hook.png"/>
        </router-link>
        <a class="nav-item" :class="{active: navIndex == 1}" href="javscript:void(0)">
          <h1>信息发布</h1>
          <img src="@/assets/images/icon/active-hook.png"/>


<!--            <router-link class="sub-item" to="/news?typeId=1&typeName=要闻">要闻</router-link>-->
<!--            <div class="divider"></div>-->
<!--            <router-link class="sub-item" to="/news?typeId=2&typeName=通知公告">通知公告</router-link>-->
<!--            <div class="divider"></div>-->
<!--            <router-link class="sub-item" to="/news">政策规章</router-link>-->
<!--            <div class="divider"></div>-->
<!--            <router-link class="sub-item" to="/news">经验介绍</router-link>-->
          <div class="sub-nav-list" v-if="!disabledNavNews">
            <div v-for="(item,index) in newsTypeList" :key="index" style="text-align: center">
              <div class="sub-item" @click="goPage(item)">{{item.name}}</div>
              <div class="divider"></div>
            </div>
            </div>
<!--            <router-link class="sub-item" to="/news?typeId=2&typeName=通知公告">通知公告</router-link>-->
<!--            <div class="divider"></div>-->
<!--            <router-link class="sub-item" to="/news?typeId=3&typeName=政策规章">政策规章</router-link>-->
<!--            <div class="divider"></div>-->
<!--            <router-link class="sub-item" to="/news?typeId=4&typeName=经验介绍">经验介绍</router-link>-->
        </a>
        <router-link class="nav-item" :class="{active: navIndex == 2}" to="/Member">
          <h1>成员单位</h1>
          <img src="@/assets/images/icon/active-hook.png"/>
        </router-link>
        <a class="nav-item" :class="{active: navIndex == 4}" href="javscript:void(0)">
          <h1>教学资源</h1>
          <img src="@/assets/images/icon/active-hook.png"/>


          <!--            <router-link class="sub-item" to="/news?typeId=1&typeName=要闻">要闻</router-link>-->
          <!--            <div class="divider"></div>-->
          <!--            <router-link class="sub-item" to="/news?typeId=2&typeName=通知公告">通知公告</router-link>-->
          <!--            <div class="divider"></div>-->
          <!--            <router-link class="sub-item" to="/news">政策规章</router-link>-->
          <!--            <div class="divider"></div>-->
          <!--            <router-link class="sub-item" to="/news">经验介绍</router-link>-->
          <div class="sub-nav-list">
            <div v-for="(item,index) in jxzyTypeList" :key="index" style="text-align: center">
              <div class="sub-item" @click="goPageUrl(item.url)">{{item.name}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <!--            <router-link class="sub-item" to="/news?typeId=2&typeName=通知公告">通知公告</router-link>-->
          <!--            <div class="divider"></div>-->
          <!--            <router-link class="sub-item" to="/news?typeId=3&typeName=政策规章">政策规章</router-link>-->
          <!--            <div class="divider"></div>-->
          <!--            <router-link class="sub-item" to="/news?typeId=4&typeName=经验介绍">经验介绍</router-link>-->
        </a>
        <router-link class="nav-item" :class="{active: navIndex == 3}" to="/activity">
          <h1>产业活动</h1>
          <img src="@/assets/images/icon/active-hook.png"/>
        </router-link>
<!--        <router-link class="nav-item" :class="{active: navIndex == 4}" to="/TeachingActivities">-->
<!--          <h1>教学活动</h1>-->
<!--          <img src="@/assets/images/icon/active-hook.png"/>-->
<!--        </router-link>-->
        <router-link class="nav-item" :class="{active: navIndex == 5}" to="/authentication">
          <h1>考试认证</h1>
          <img src="@/assets/images/icon/active-hook.png"/>
        </router-link>
<!--        <router-link class="nav-item" :class="{active: navIndex == 6}" to="/TeacherExperts">-->
<!--          <h1>师资力量</h1>-->
<!--          <img src="@/assets/images/icon/active-hook.png"/>-->
<!--        </router-link>-->
<!--        <router-link class="nav-item" :class="{active: navIndex == 7}" to="/course">-->
<!--          <h1>开放课程</h1>-->
<!--          <img src="@/assets/images/icon/active-hook.png"/>-->
<!--        </router-link>-->
<!--        <router-link class="nav-item" :class="{active: navIndex == 8}" to="/project">-->
<!--          <h1>实训项目</h1>-->
<!--          <img src="@/assets/images/icon/active-hook.png"/>-->
<!--        </router-link>-->
        <router-link class="nav-item" :class="{active: navIndex == 9}" to="/aboutus">
          <h1>关于我们</h1>
          <img src="@/assets/images/icon/active-hook.png"/>
        </router-link>
      </div>
    </div>

  </header>
</template>

<script>
import {reqNewsTypeList} from "@/api/home";
import requests from '@/api/request';
export default {
  name: "Header",
  data() {
    return {
      headPhoto:'',
      loginURL : process.env.VUE_APP_OAUTH2_BASE_URL + '/login/' + process.env.VUE_APP_OAUTH2_CLIENT_ID,
      registerURL : process.env.VUE_APP_OAUTH2_BASE_URL + '/register',
      searchVal: '',
      loginFlag: false,
      backgroundManagement: false,
      name: '',
      newsTypeList: [],
      jxzyTypeList: [{
        name: '师资力量',
        url: '/TeacherExperts'
      },{
        name: '教学活动',
        url: '/TeachingActivities'
      },{
        name: '开放课程',
        url: '/course'
      },{
        name: '实训项目',
        url: '/project'
      }]
    }
  },
  props: {
    navIndex: {
      type: Number,
      default: 0
    },
    disabledNavNews: {
      type: Boolean,
      default: false
    }
  },
  computed: {requests},
  methods: {
    async reqNewsTypeList(){
       const req = await reqNewsTypeList();
      this.newsTypeList =  req.result.records;
    },
    search() {
      this.$router.push({path: '/searchResult', query: {searchVal: this.searchVal}})
    },
    // skipBack() {
    //   let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    //   window.open("http://localhost:3000/user/login?loginkey=" + userInfo.token)
    // },
    skipBack() {
      let sysUser = JSON.parse(localStorage.getItem("sysUser"))
      window.open(`${process.env.VUE_APP_BACKSTAGE_URL}/user/login?loginkey=` + sysUser.token)
    },
    skipinfo(){
      window.open(`${process.env.VUE_APP_OAUTH2_BASE_URL}/personal`)
    },
    myRegistration(){
      this.$router.replace('/myRegistration')
    },
    clearOut(){
      const item =JSON.parse( localStorage.getItem("sysUser"));
      if (item){
        requests({
        url: "/sys/logout",method:"get",headers:{
          "X-Access-Token" : item.token
          }
      })
      }
      localStorage.removeItem("userInfo");
      localStorage.removeItem("sysUser");
      window.location.href=`${process.env.VUE_APP_OAUTH2_BASE_URL}/events/logout`;
    },
    async getNewsTypeList() {
      let {result} = await reqNewsTypeList()
      this.newsTypeList = result.records
    },
    goPage(item){
      this.$router.replace(`/news?typeId=${item.id}&typeName=${item.name}`)
    },
    goPageUrl(url){
      this.$router.replace(url)
    }
  },
  mounted() {
    console.log('打印跳转地址',this.loginURL)
    console.log('打印配置文件中的基座地址',`${process.env.VUE_APP_OAUTH2_BASE_URL}`)
    this.reqNewsTypeList();
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.loginFlag = true
      this.name = userInfo.name;
      this.headPhoto = userInfo.headPhoto;
    }
    var sysUser = JSON.parse(localStorage.getItem("sysUser"));
    if (sysUser != null) {
      this.backgroundManagement = true
    }
    this.getNewsTypeList()
    // this.$store.dispatch('getNewsTypeList')
  }
}
</script>

<style scoped lang="scss">
.header {
  position: relative;
  width: 100vw;
  height: 14em;
  background: url('@/assets/images/header/banner.png') center top no-repeat;
  background-size: cover;
  display: flex;


  .welcome {
    z-index: 100;
    position: absolute;
    right: 1em;
    top: 1.7em;
    display: flex;
    align-items: flex-start;
    .info-text{
      position: relative;
    }

    .info:hover {
      visibility: revert;
    }
    .info{
      display: none;
      position: absolute;
      top: 32px;
      .arrows{
        width: 10px;
        height: 10px;
        position: absolute;
        left: 2em;
        top: -5px;
        background-color: #E4E9F1;
        transform: rotate(45deg);
        border-top-left-radius: 3px;
      }
      .drop-down {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 5em;
        height: 7em;
        background-color: #E4E9F1;
        li:hover {
          background-color: #406bb2;
          color: white;
        }
        li {
          cursor: pointer;
          font-size: .8em;
          text-align: center;
          padding: 5px;
          z-index: 999;
        }
      }
    }
    .skip {
      width: 150px;
      background-color: #2780F3;
      color: #B2D2E1;
      margin-right: 20px;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;
      &:hover {
        font-style: italic;
        text-decoration: underline;
      }
    }
    .head-photo{
      width: 22px;
      height: 22px;
      cursor: pointer;
      vertical-align: bottom;
      margin-right: 3px;
    }
    .photo-box{
      width: 28px;
      height: 50px;
      display: flex;
      justify-content: center;

    }

    .photo-box:hover ~.info{
      display: block;
    }
    .photo-box:hover img{
      position: relative;
      top: -2px;
      //box-shadow: 2px 2px 3px gray;
    }
    .info:hover{
      display: block;
    }


    span {
      font-size: 18px;
      color: white;
    }
  }

  .btns {
    position: absolute;
    right: 120px;
    top: 30px;
    width: 160px;
    height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      font-size: 18px;
      color: #FFF;

      &:hover {
        color: #FFF !important;
        text-decoration: none !important;
      }
    }

    .divider {
      font-size: 18px;
      margin: 0 18px;
      color: #fff;
    }
  }

  .top {
    width: 1400px;
    position: relative;
    //position: absolute;
    //right: 310px;
    //top: 11rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    //flex-direction: column;
    align-items: center;

    .slogan {
      width: 670px;
      height: auto;
      margin-right: 3rem;
    }

    .search-box {
      position: absolute;
      right: 1em;
      width: 600px;

      .search-input {
        box-sizing: border-box;
        width: 100%;
        height: 54px;
        border-radius: 26px;
        background-color: #FFF;
        padding-left: 44px;
        outline: none;
        border: none;
        font-size: 18px;
      }

      .icon-search {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
      }

      .btn-search {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .nav-box {
    position: absolute;
    z-index: 9;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    background: linear-gradient(0deg, #0E66CC, #0E59C5);
    box-shadow: 0px 4px 20px 0px rgba(12, 99, 180, 0.3);

    .navbar {
      box-sizing: border-box;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      height: 54px;
      padding: 0 32px;
      /*background: linear-gradient(0deg, #0E66CC, #0E59C5);
      box-shadow: 0px 4px 20px 0px rgba(12,99,180,0.3);*/
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-item {
        position: relative;
        width: 100px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all linear 0.1s;

        h1 {
          font-size: 18px;
          color: #FFF;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.09);

          .sub-nav-list {
            display: flex;
            flex-direction: column;
          }
        }

        &.active {
          background-color: rgba(255, 255, 255, 0.09);

          img {
            display: block;
          }
        }

        img {
          position: absolute;
          bottom: 2px;
          left: 50%;
          transform: translateX(-50%);
          width: 13px;
          height: 11px;
          display: none;
        }

        .sub-nav-list {
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 100%;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          background-color: #FFF;
          box-shadow: 0px 2px 18px 0px rgba(46, 62, 94, 0.13);
          display: none;

          .sub-item {
            box-sizing: border-box;
            width: 100%;
            height: 50px;
            font-size: 16px;
            color: #666;
            text-align: center;
            line-height: 50px;
            text-decoration: none;

            &:hover {
              z-index: 999;
              color: #1499F6;
              text-decoration: none;
            }
          }

          .divider {
            width: 80%;
            height: 1px;
            background-color: #EBEBEB;
            margin-left: 10%;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1580px) and (min-width: 1110px) {
  .header {
    height: 14rem;

    .btns {
      position: absolute;
      right: 80px;
      top: 20px;

      .btn {
        font-size: 16px;
      }

      .divider {
        font-size: 16px;
        margin: 0 16px;
      }
    }

    .top {
      .slogan {
        width: 520px;
      }

      .search-box {
        width: 480px;
        height: 46px;

        .search-input {
          width: 480px;
          height: 46px;
        }

        .btn-search {
          right: 4px;
        }
      }
    }

    .nav-box {
      height: 50px;

      .navbar {
        height: 50px;
        padding: 0 140px;

        .nav-item {
          h1 {
            font-size: 16px;
          }

          img {
            width: 12px;
            height: 9px;
            bottom: 2px;
          }

          .sub-nav-list {
            .sub-item {
              font-size: 16px;
            }
          }
        }
      }
    }

  }
}


@media screen and (max-width: 1100px) and (min-width: 480px) {
  .header {
    width: 100%;
    height: 38em;
    .nav-box{
      height: 5em;
      .navbar{
        height: 100%;

      }
    }
    .nav-item h1{
      font-size: 1.5em !important;
    }
    .btns {
      top: 1em;
      right: 2em;
      width: 10em;
      height: 3em;
      .divider {
        font-size: 1.5em;
        margin: 0 1em;
        color: #fff;
      }
      .btn{
        font-size: 1.5em;
      }
    }
    .top {
      width: 100%;
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .slogan {
        position: absolute;
        left: 17em;
        top: 5.5em;
        width: 70%;
      }

      .welcome {
        width: 28%;

        .skip {
          width: 40%;
          cursor: pointer;
          margin-right: 2em;
          font-size: 1em;
          font-weight: bold;
          letter-spacing: .1em;
        }

        span {
          font-size: 1em;
        }
      }

      .search-box {
        font-size: 1em;
        position: absolute;
        width: 50%;
        left: 28em;
        top: 17em;
        .icon-search {
          left: 1.5em;
          max-width: 17px;
          max-height: 19px;
        }
        .el-button.is-round {
          width: 5em;
          height: 3em;
          border-radius: 1.5em;
          padding: .8em 1.6em;
          font-size: 1.5em;
        }

        .search-input {
          border-radius: 1em;
          padding-left: 1.6em;
          font-size: 3em;
          width: 100%;
          height: 2em;
        }

        .btn-search {
          right: 4px;
        }
      }
    }

  }
}
@media screen and (max-width: 1280px) and (min-width: 1280px) {
  .header {
    .top {
      .slogan[data-v-2f029f00] {
        width: 520px;
        position: absolute;
        top: 50px;
        left: 150px;
      }
      .search-box[data-v-2f029f00] {
        right: 5em;
      }
    }
  }
}
</style>
